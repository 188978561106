@import "variables";

.recipe-hero {
	// padding-bottom: 2em;
	padding-top: 0;
	padding-bottom: 0;

	.recipe-meta {
		background: none;
		display: flex;
		flex-direction: column;
		border-bottom: none;
		justify-content: center;

		.sub-hed {
			font-size: 28px;

			@media(min-width: 60em) {
				font-size: 28px;
			}
		}

		.meta {
			width: 100%;
			display: flex;
			justify-content: space-around;
			padding: .25em 0 .25em 0;
			border-top: 1px solid $med-grey;

			&:last-of-type {
				border-bottom: 1px solid $med-grey;
			}

			dt {
				color: $xdrk-grey;
				font-size: 1em;
				&::after {
					content: ":";
				}
			}

			dd,
			dt {
				margin: 10px;
				width: 50%;
				text-align: center;
				// text-align: left;
			}
		}

		.credit {
			// margin-top: auto;
			margin-top: 0;
			// border-bottom: 1px solid $med-grey;
			display: block;
			font-style: italic;
			font-size: 1.1em;
			text-align: center;
			color: $drk-grey;
			padding-bottom: 1em;
			margin-bottom: .25em;

			a {
				display: inline-block;
				color: $eb-blue;
				font-style: normal;
				text-decoration: none;
			}
		}
	}
	.central-primary {
		padding: 0;
		max-width: none;
	}

	.recipe-ratings {
		text-align: center;
		font-size: 18px;

		&__count {
			a {
				display: block;
			}
		}

		@media(min-width: 60em) {
			font-size: 22px;
		}
	}


	// .share-cta {
	// 	margin-top: .75em;

	// 	.share-hed {
	// 		display: inline-block;
	// 	}

	// 	.social-nav {
	// 		display: inline-flex;
	// 	}
	// }

	@media(min-width: 60em) {
		.central {
			width: 100%;

			.central-primary {
				padding: 0;
				//width: 50%;
			}

			.central-secondary {
				// width: 50%;
				padding: 0;
				padding-left: 1.736111112%;

				.recipe-hed {
					text-align: center;
					margin-inline: 0;
				}

				.meta-info {
					margin-top: auto;
				}
			}
		}
	}

	.central-secondary {
		display: flex;
	}

	.share-cta {
		display: block;
		text-align: center;
	}

	.recipe-lede {
		font-size: 1.5em; // 24px

		@media(min-width: 60em) {
			font-size: 2em; // 32px
			margin-top: 3rem;
			// margin-top: 0;
			padding: 0 12.5%;
		}
	}
}

.recipe-actions {
	display: flex;
	padding: 1em 0;
	justify-content: center;
	margin-top: auto;

	&--left {
		justify-content: flex-start;
	}

	@media(min-width: 60em) {
	}
}

.recipe {
	background: $xlt-grey;
	

	@media(min-width: 60em) {
		padding-top: 7.5em;
	}

	.scoop {
		fill: $xlt-grey;
	}
	.recipe-ingredients {
		@media(min-width: 60em) {
			position: sticky;
			top: 1em;
		}
	}

	// .recipe-actions {
	// 	display: flex;
	// 	justify-content: center;
	// 	margin-bottom: 2rem;
	// }

	.recipe-detail {
		padding-top: 2em;
		max-width: 108rem; // 1728px
		margin-left: auto;
		margin-right: auto;

		.central-primary,
		.central-secondary {
			padding: 0;
		}

		@media(min-width: 60em) {
			&.central {
				width: 100%;

				> * {
					width: 100%;
				}

				.central-primary {
					padding: 0;
					width: 50%;
				}

				.central-secondary {
					max-width: 50%;
					padding: 0;
					padding-right: 1.736111112%;
				}
			}
		}

		.recipe-block {
			border: 1px solid transparent;
			margin: .75em 0;
			background: #fff;
			border-radius: .25em;
			color: $xdrk-grey;
			font-size: 1.15em;

			ul,
			ol {
				padding: 0;
				margin: 0;
				list-style: none;
				li {
					margin: 1.5em 0;
				}
			}

			ol {
				counter-reset: inst;

				li {
					position: relative;
					padding-left: 1.5em;
					font-size: 1.1em;
				}

				li::before {
					position: absolute;
					left: 0;
					top: 4px;
					font-size: 18px;
					counter-increment: inst;
					content: counter(inst) ".";
					font-weight: bold;
					font-style: italic;
					color: $eb-blue;
				}
			}
		}
	}

	.recipe-hed {
		font-size: 2.125em; // 34px
		letter-spacing: -0.000417em;
		display: block;
		padding: 1em 1em .75em 1em;
		margin: 0;
		text-align: center;
		border-bottom: 1px solid $xlt-grey;
		margin: 0 auto;
		@media(min-width: 60em) {
			font-size: 4em; // 64px
			letter-spacing: -0.000625em;
		}
	}

	.inset {
		padding: 0 2.5em;
		margin-bottom: 2.5em;
	}

	.promo-block {
		text-align: center;
		background: $eggshell;
		border: solid $lt-grey;
		border-width: 1px 0;
		margin: -1px 0 0 0;
		padding: 2em 5%;
		border-radius: 0;

		.lede {
			margin-bottom: 1em;
			font-size: 0.978261em; // 18px

			@media(min-width: 60em) {
					font-size: 1.304348em; // 24px
			}
		}
	}

	.nutrition-facts {
		td {
			font-size: 1.25rem; // 20px
			font-weight: 700;
		}
	}
}


@media(max-width: 59.9375em) {
	.section.recipe-header {
		padding-bottom: 0;
		
		& + .section {
			padding-top: 0;
		}
	}
}

@media print {
	@import "_recipe-print";
	main {
		display: block;
	}
}
@media screen {
	.recipe-print {
		@import "_recipe-print";
	}
}