.recipe-hero {
  padding-top: 0;
  padding-bottom: 0;
}
.recipe-hero .recipe-meta {
  background: none;
  display: flex;
  flex-direction: column;
  border-bottom: none;
  justify-content: center;
}
.recipe-hero .recipe-meta .sub-hed {
  font-size: 28px;
}
@media (min-width: 60em) {
  .recipe-hero .recipe-meta .sub-hed {
    font-size: 28px;
  }
}
.recipe-hero .recipe-meta .meta {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0.25em 0 0.25em 0;
  border-top: 1px solid #b8b0b6;
}
.recipe-hero .recipe-meta .meta:last-of-type {
  border-bottom: 1px solid #b8b0b6;
}
.recipe-hero .recipe-meta .meta dt {
  color: #5a5666;
  font-size: 1em;
}
.recipe-hero .recipe-meta .meta dt::after {
  content: ":";
}
.recipe-hero .recipe-meta .meta dd,
.recipe-hero .recipe-meta .meta dt {
  margin: 10px;
  width: 50%;
  text-align: center;
}
.recipe-hero .recipe-meta .credit {
  margin-top: 0;
  display: block;
  font-style: italic;
  font-size: 1.1em;
  text-align: center;
  color: #716873;
  padding-bottom: 1em;
  margin-bottom: 0.25em;
}
.recipe-hero .recipe-meta .credit a {
  display: inline-block;
  color: #1b3687;
  font-style: normal;
  text-decoration: none;
}
.recipe-hero .central-primary {
  padding: 0;
  max-width: none;
}
.recipe-hero .recipe-ratings {
  text-align: center;
  font-size: 18px;
}
.recipe-hero .recipe-ratings__count a {
  display: block;
}
@media (min-width: 60em) {
  .recipe-hero .recipe-ratings {
    font-size: 22px;
  }
}
@media (min-width: 60em) {
  .recipe-hero .central {
    width: 100%;
  }
  .recipe-hero .central .central-primary {
    padding: 0;
  }
  .recipe-hero .central .central-secondary {
    padding: 0;
    padding-left: 1.736111112%;
  }
  .recipe-hero .central .central-secondary .recipe-hed {
    text-align: center;
    margin-inline: 0;
  }
  .recipe-hero .central .central-secondary .meta-info {
    margin-top: auto;
  }
}
.recipe-hero .central-secondary {
  display: flex;
}
.recipe-hero .share-cta {
  display: block;
  text-align: center;
}
.recipe-hero .recipe-lede {
  font-size: 1.5em;
}
@media (min-width: 60em) {
  .recipe-hero .recipe-lede {
    font-size: 2em;
    margin-top: 3rem;
    padding: 0 12.5%;
  }
}

.recipe-actions {
  display: flex;
  padding: 1em 0;
  justify-content: center;
  margin-top: auto;
}
.recipe-actions--left {
  justify-content: flex-start;
}
.recipe {
  background: #f5f2f3;
}
@media (min-width: 60em) {
  .recipe {
    padding-top: 7.5em;
  }
}
.recipe .scoop {
  fill: #f5f2f3;
}
@media (min-width: 60em) {
  .recipe .recipe-ingredients {
    position: sticky;
    top: 1em;
  }
}
.recipe .recipe-detail {
  padding-top: 2em;
  max-width: 108rem;
  margin-left: auto;
  margin-right: auto;
}
.recipe .recipe-detail .central-primary,
.recipe .recipe-detail .central-secondary {
  padding: 0;
}
@media (min-width: 60em) {
  .recipe .recipe-detail.central {
    width: 100%;
  }
  .recipe .recipe-detail.central > * {
    width: 100%;
  }
  .recipe .recipe-detail.central .central-primary {
    padding: 0;
    width: 50%;
  }
  .recipe .recipe-detail.central .central-secondary {
    max-width: 50%;
    padding: 0;
    padding-right: 1.736111112%;
  }
}
.recipe .recipe-detail .recipe-block {
  border: 1px solid transparent;
  margin: 0.75em 0;
  background: #fff;
  border-radius: 0.25em;
  color: #5a5666;
  font-size: 1.15em;
}
.recipe .recipe-detail .recipe-block ul,
.recipe .recipe-detail .recipe-block ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
.recipe .recipe-detail .recipe-block ul li,
.recipe .recipe-detail .recipe-block ol li {
  margin: 1.5em 0;
}
.recipe .recipe-detail .recipe-block ol {
  counter-reset: inst;
}
.recipe .recipe-detail .recipe-block ol li {
  position: relative;
  padding-left: 1.5em;
  font-size: 1.1em;
}
.recipe .recipe-detail .recipe-block ol li::before {
  position: absolute;
  left: 0;
  top: 4px;
  font-size: 18px;
  counter-increment: inst;
  content: counter(inst) ".";
  font-weight: bold;
  font-style: italic;
  color: #1b3687;
}
.recipe .recipe-hed {
  font-size: 2.125em;
  letter-spacing: -0.000417em;
  display: block;
  padding: 1em 1em 0.75em 1em;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #f5f2f3;
  margin: 0 auto;
}
@media (min-width: 60em) {
  .recipe .recipe-hed {
    font-size: 4em;
    letter-spacing: -0.000625em;
  }
}
.recipe .inset {
  padding: 0 2.5em;
  margin-bottom: 2.5em;
}
.recipe .promo-block {
  text-align: center;
  background: #f9f7f8;
  border: solid #e0d7d9;
  border-width: 1px 0;
  margin: -1px 0 0 0;
  padding: 2em 5%;
  border-radius: 0;
}
.recipe .promo-block .lede {
  margin-bottom: 1em;
  font-size: 0.978261em;
}
@media (min-width: 60em) {
  .recipe .promo-block .lede {
    font-size: 1.304348em;
  }
}
.recipe .nutrition-facts td {
  font-size: 1.25rem;
  font-weight: 700;
}

@media (max-width: 59.9375em) {
  .section.recipe-header {
    padding-bottom: 0;
  }
  .section.recipe-header + .section {
    padding-top: 0;
  }
}
@media print {
  #section-video {
    display: none;
  }

  .recipe-header .lede {
    display: none;
  }
  .recipe-header .breadcrumb {
    margin-top: 1.75em;
  }
  .recipe-header .breadcrumb li {
    border-color: #b8b0b6;
  }
  .recipe-header .breadcrumb li a {
    color: #716873;
  }
  .recipe-header .breadcrumb li a[aria-current=page] {
    color: #000;
  }

  .scooped {
    padding-bottom: 5px !important;
  }

  .recipe {
    padding-top: 0 !important;
  }

  .recipe-detail-page .inner-header {
    padding-top: 0;
  }

  .recipe-hero .central-primary {
    order: 2;
    padding-left: 30px;
  }
  .recipe-hero .central-secondary {
    order: 1;
  }

  .inner-header .hero-art img {
    display: none;
  }

  .masthead,
.site-foot,
nav,
.promo-block__container {
    display: none !important;
  }

  .share-cta,
.recipe-teasers,
[role=complementary] {
    display: none !important;
  }

  .recipe-hed {
    border-bottom: 5px solid #716873 !important;
    padding-left: 2px !important;
    padding-bottom: 0.5em;
    font-size: 48px;
    text-align: center;
    width: 100%;
    max-width: 100% !important;
  }

  svg {
    display: none !important;
  }

  .recipe-block .inset {
    padding: 0 2px;
  }

  main {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2.5em 7.5em 2.5em;
    overflow: auto;
    flex-direction: row-reverse;
    max-width: 1200px;
    margin: 0 auto;
  }
  main .recipe-header {
    flex-grow: 4;
    width: 100%;
  }

  .scooped .scoop {
    display: none !important;
  }

  .central {
    display: flex;
  }
  .central .central-primary {
    padding-bottom: 0;
    padding-top: 1.75em;
    width: 50%;
  }
  .central .central-secondary {
    padding-top: 0;
    min-width: auto;
    padding-bottom: 0;
    width: 50%;
  }
  .central .central-secondary .credit {
    font-size: 1.2em;
    border-bottom: none;
  }
  .central .central-secondary .meta-info {
    margin-top: 0;
  }
  .central > * {
    padding-left: 0;
    padding-right: 0;
  }

  .central.recipe-detail {
    display: block;
    max-width: 100%;
    width: 100%;
  }

  .recipe .recipe-detail {
    max-width: 100%;
    width: 100%;
  }

  .recipe .recipe-detail.central .central-secondary,
.recipe .recipe-detail.central .central-primary {
    width: 100% !important;
  }

  .recipe-hero {
    max-width: 100% !important;
    width: 100%;
  }
  .recipe-hero img {
    width: 100%;
  }
  .recipe-hero .inset {
    padding: 0;
  }
  .recipe-hero .recipe-lede {
    font-size: 1em;
  }

  .recipe {
    width: 100%;
    background: none;
  }
  .recipe > *:not(.central) {
    display: none;
  }
  .recipe .promo-block {
    border: none;
    background: none;
  }
  .recipe .promo-block p {
    line-height: 1.6;
  }
  .recipe .promo-block .btn {
    display: none;
  }

  [class*=-hed],
p {
    color: #000;
  }

  table caption {
    font-size: 1.4em;
    padding-bottom: 0.5em;
    color: #000 !important;
    border-color: #716873 !important;
  }
  table tr td {
    color: #000 !important;
  }

  .recipe-nutrition {
    padding-bottom: 2em;
  }

  main {
    display: block;
  }
}
@media screen {
  .recipe-print #section-video {
    display: none;
  }
  .recipe-print .recipe-header .lede {
    display: none;
  }
  .recipe-print .recipe-header .breadcrumb {
    margin-top: 1.75em;
  }
  .recipe-print .recipe-header .breadcrumb li {
    border-color: #b8b0b6;
  }
  .recipe-print .recipe-header .breadcrumb li a {
    color: #716873;
  }
  .recipe-print .recipe-header .breadcrumb li a[aria-current=page] {
    color: #000;
  }
  .recipe-print .scooped {
    padding-bottom: 5px !important;
  }
  .recipe-print .recipe {
    padding-top: 0 !important;
  }
  .recipe-print .recipe-detail-page .inner-header {
    padding-top: 0;
  }
  .recipe-print .recipe-hero .central-primary {
    order: 2;
    padding-left: 30px;
  }
  .recipe-print .recipe-hero .central-secondary {
    order: 1;
  }
  .recipe-print .inner-header .hero-art img {
    display: none;
  }
  .recipe-print .masthead,
.recipe-print .site-foot,
.recipe-print nav,
.recipe-print .promo-block__container {
    display: none !important;
  }
  .recipe-print .share-cta,
.recipe-print .recipe-teasers,
.recipe-print [role=complementary] {
    display: none !important;
  }
  .recipe-print .recipe-hed {
    border-bottom: 5px solid #716873 !important;
    padding-left: 2px !important;
    padding-bottom: 0.5em;
    font-size: 48px;
    text-align: center;
    width: 100%;
    max-width: 100% !important;
  }
  .recipe-print svg {
    display: none !important;
  }
  .recipe-print .recipe-block .inset {
    padding: 0 2px;
  }
  .recipe-print main {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2.5em 7.5em 2.5em;
    overflow: auto;
    flex-direction: row-reverse;
    max-width: 1200px;
    margin: 0 auto;
  }
  .recipe-print main .recipe-header {
    flex-grow: 4;
    width: 100%;
  }
  .recipe-print .scooped .scoop {
    display: none !important;
  }
  .recipe-print .central {
    display: flex;
  }
  .recipe-print .central .central-primary {
    padding-bottom: 0;
    padding-top: 1.75em;
    width: 50%;
  }
  .recipe-print .central .central-secondary {
    padding-top: 0;
    min-width: auto;
    padding-bottom: 0;
    width: 50%;
  }
  .recipe-print .central .central-secondary .credit {
    font-size: 1.2em;
    border-bottom: none;
  }
  .recipe-print .central .central-secondary .meta-info {
    margin-top: 0;
  }
  .recipe-print .central > * {
    padding-left: 0;
    padding-right: 0;
  }
  .recipe-print .central.recipe-detail {
    display: block;
    max-width: 100%;
    width: 100%;
  }
  .recipe-print .recipe .recipe-detail {
    max-width: 100%;
    width: 100%;
  }
  .recipe-print .recipe .recipe-detail.central .central-secondary,
.recipe-print .recipe .recipe-detail.central .central-primary {
    width: 100% !important;
  }
  .recipe-print .recipe-hero {
    max-width: 100% !important;
    width: 100%;
  }
  .recipe-print .recipe-hero img {
    width: 100%;
  }
  .recipe-print .recipe-hero .inset {
    padding: 0;
  }
  .recipe-print .recipe-hero .recipe-lede {
    font-size: 1em;
  }
  .recipe-print .recipe {
    width: 100%;
    background: none;
  }
  .recipe-print .recipe > *:not(.central) {
    display: none;
  }
  .recipe-print .recipe .promo-block {
    border: none;
    background: none;
  }
  .recipe-print .recipe .promo-block p {
    line-height: 1.6;
  }
  .recipe-print .recipe .promo-block .btn {
    display: none;
  }
  .recipe-print [class*=-hed],
.recipe-print p {
    color: #000;
  }
  .recipe-print table caption {
    font-size: 1.4em;
    padding-bottom: 0.5em;
    color: #000 !important;
    border-color: #716873 !important;
  }
  .recipe-print table tr td {
    color: #000 !important;
  }
  .recipe-print .recipe-nutrition {
    padding-bottom: 2em;
  }
}