#section-video {
	display: none;
}

.recipe-header {
	.lede {
		display: none;
	}

	.breadcrumb {
		margin-top: 1.75em;

		li {
			border-color: $med-grey;
			a {
				color: $drk-grey;
				&[aria-current="page"] {
					color: #000;
				}
			}
		}
	}
}

.scooped {
	padding-bottom: 5px !important;
}

.recipe {padding-top:0 !important;}

.recipe-detail-page .inner-header {
	padding-top: 0;
}

.recipe-hero {
	
	.central-primary {
		order: 2;
		padding-left: 30px;

	}
	
	.central-secondary {
		order: 1;
	}
	
}


.inner-header .hero-art img {
	display: none;
}

.masthead,
.site-foot,
nav,
.promo-block__container {
	display: none !important;
}

.share-cta,
.recipe-teasers,
[role="complementary"] {
	display: none !important;
}

.recipe-hed {
	border-bottom: 5px solid $drk-grey !important;
	padding-left: 2px !important;
	padding-bottom: .5em;
	font-size: 48px;
	text-align: center;
	width: 100%;
	max-width: 100% !important;
}

svg { display:none !important;}

.recipe-block .inset {
	padding: 0 2px;
}

main {
	display: flex;
	flex-wrap: wrap;
	padding: 0 2.5em 7.5em 2.5em;
	overflow: auto;
	flex-direction: row-reverse;
	max-width: 1200px;
	margin: 0 auto;

	.recipe-header {
		flex-grow: 4;
		width: 100%;
	}
}

.scooped .scoop {
	display: none !important;
}

.central {
	display: flex;
	
	.central-primary {
		padding-bottom: 0;
		padding-top: 1.75em;
		width: 50%;
	}

	.central-secondary {
		padding-top: 0;
		min-width: auto;
		padding-bottom: 0;
		width: 50%;

		.credit {
			font-size: 1.2em;
			border-bottom: none;
		}

		.meta-info {
			margin-top: 0;
		}
	}

	> * {
		padding-left: 0;
		padding-right: 0;
	}
}

.central.recipe-detail {
	display: block;
	
	max-width: 100%;
	width: 100%;
}

.recipe .recipe-detail {
	max-width: 100%;
	width: 100%;
}

.recipe .recipe-detail.central .central-secondary,
.recipe .recipe-detail.central .central-primary {
	width: 100% !important;
}

.recipe-hero {
	max-width: 100% !important;
	width: 100%;
	
	img {
		//display: none;
		width: 100%
	}
	
	.inset {
		padding: 0;
	}
	
	.recipe-lede {
		font-size: 1em;
	}
}

.recipe {
	width: 100%;
	background: none;

	& > *:not(.central) {
		display: none;
	}

	.promo-block {
		border: none;
		background: none;

		p {
			line-height: 1.6;
		}

		.btn {
			display: none;
		}
	}
}

[class*="-hed"],
p {
	color: #000;
}

table {

	caption {
		font-size: 1.4em;
		padding-bottom: .5em;
		color: #000 !important;
		border-color: $drk-grey !important;
	}

	tr td {
		color: #000 !important;
	}
}

.recipe-nutrition {
	padding-bottom: 2em;
}

